import React from "react";
import i18next from "../../common/localization/i18n";
import FormModel from "../interfaces/FormModel";
import SaveForm from "../api/SaveForm";
import { DialogType } from "../../common/enums/DialogType";
import FormStateParams from "../interfaces/FormStateParams";
import SetTheme from "../../common/functions/SetTheme";

const setCookieName = (surveyCreator: any, formStateParams: FormStateParams) => {
  surveyCreator.survey.cookieName = surveyCreator.survey.limitToOneSubmission
    ? formStateParams.webContentId
    : "";
};

const SetSurveyJson = (surveyCreator: any, model: FormModel) => {
  surveyCreator.JSON = JSON.parse(model.json);
};

function Save(
  surveyCreator: any,
  routeParams: FormStateParams,
  setLoading: any,
  formModel: FormModel | null,
  dialog: any,
) {
  // @ts-ignore
  setLoading(true);
  setCookieName(surveyCreator, routeParams);
  let theme = null;
  if (surveyCreator.themeEditor.isModified) {
    surveyCreator.theme.themeName = "custom";
    theme = surveyCreator.theme;
  }
  SaveForm(
    routeParams.webContentId,
    surveyCreator.JSON,
    formModel?.webContentName,
    theme,
    routeParams,
  )
    ?.then((data: any) => {
      SetTheme(surveyCreator, data);
      // @ts-ignore
      setLoading(false);
    })
    .catch(async (e: any) => {
      // @ts-ignore
      setLoading(false);
      if (e.message === "Unauthorized") {
        if (
          await dialog(
            DialogType.Confirm,
            i18next.t("NEW_FORM_EDITOR.UNAUTHORIZED_DIALOG.TITLE"),
            i18next.t("NEW_FORM_EDITOR.UNAUTHORIZED_DIALOG.TEXT"),
          )
        ) {
          return <>getFormJson error</>;
        }
      } else if (
        await dialog(
          DialogType.Confirm,
          i18next.t("NEW_FORM_EDITOR.SAVING_SERVER_DIALOG.TITLE"),
          i18next.t("NEW_FORM_EDITOR.SAVING_SERVER_DIALOG.TEXT"),
        )
      ) {
        return <>getFormJson error</>;
      }
      return <>getFormJson error</>;
    });
}

export default async function SaveSurveyFunc(
  surveyCreator: any,
  stateParams: FormStateParams,
  setLoading: any,
  formModel: FormModel | null,
  dialog: any,
) {
  const phoneFields = surveyCreator
    .getAllQuestions()
    .filter((q: any) => q.crmFieldType === "phone");

  const phoneSubscriptionFields = surveyCreator
    .getAllQuestions()
    .filter((q: any) => q.crmFieldType === "subscriptionlist")
    .find((i: any) => i.choices?.find((c: any) => c?.subscriptionType === "phone"));

  if (!(phoneFields?.length > 0) && phoneSubscriptionFields) {
    if (
      await dialog(
        DialogType.Confirm,
        i18next.t("NEW_FORM_EDITOR.NO_PHONE_FIELD_DIALOG.TITLE"),
        i18next.t("NEW_FORM_EDITOR.NO_PHONE_FIELD_DIALOG.TEXT"),
        i18next.t("NEW_FORM_EDITOR.NO_PHONE_FIELD_DIALOG.CONTINUE"),
      )
    ) {
      Save(surveyCreator, stateParams, setLoading, formModel, dialog);
      return "";
    }
  }

  const emailFields = surveyCreator.getAllQuestions().filter((q: any) => q.inputType === "email");

  if (!(emailFields?.length > 0)) {
    if (
      await dialog(
        DialogType.Confirm,
        i18next.t("NEW_FORM_EDITOR.NO_EMAIL_FIELD_DIALOG.TITLE"),
        i18next.t("NEW_FORM_EDITOR.NO_EMAIL_FIELD_DIALOG.TEXT"),
        i18next.t("NEW_FORM_EDITOR.NO_EMAIL_FIELD_DIALOG.CONTINUE"),
      )
    ) {
      Save(surveyCreator, stateParams, setLoading, formModel, dialog);
      return "";
    }
  } else {
    const isEmailMapped =
      emailFields.find((f: any) => f.getPropertyValue("contactMap")) ||
      emailFields.find((f: any) => f.getPropertyValue("leadMap"));

    if (!isEmailMapped) {
      if (
        await dialog(
          DialogType.Confirm,
          i18next.t("NEW_FORM_EDITOR.EMAIL_NOT_MAPPED_DIALOG.TITLE"),
          i18next.t("NEW_FORM_EDITOR.EMAIL_NOT_MAPPED_DIALOG.TEXT"),
          i18next.t("NEW_FORM_EDITOR.EMAIL_NOT_MAPPED_DIALOG.CONTINUE"),
        )
      ) {
        Save(surveyCreator, stateParams, setLoading, formModel, dialog);
        return "";
      }
    } else {
      Save(surveyCreator, stateParams, setLoading, formModel, dialog);
    }
  }
}
