const subscriptionsEditorStringsEn = {
  completingSurvey: "Your preference has been saved",
  survey: {
    externalHelpLink: "Watch and learn how to create a Preference Center",
    addLogicItem: "Create a rule to customize the flow of the page.",
    dropQuestion: "Please drop a field here from the Toolbox.",
    deleteQuestion: "Delete Field",
  },
  qt: {
    dropdown: "List",
    radiogroup: "Radiogroup",
    checkbox: "Subscriptions",
    boolean: "Boolean",
    phone_field: "Phone",
    email_field: "Email",
    consent: "Consent",
    global_unsubscribe_email: "Unsubscribe all - Email",
    global_unsubscribe_sms: "Unsubscribe all - SMS",
  },
  ed: {
    survey: "Preference Center",
    settings: "Settings",
    settingsTooltip: "Open settings",
    surveySettingsTooltip: "Open settings",
    testSurvey: "Preview",
    testSurveyAgain: "Preview page again",
    testSurveyWidth: "Page Width:",
    embedSurvey: "Embed Page",
    saveSurvey: "Save Page",
    saveSurveyTooltip: "Save Page",
    surveyResults: "Page Results ",
    surveyTypeName: "Preference Center",
    questionTypeName: "Field",
    newQuestionName: "field",
    addNewQuestion: "New Field",
    surveyPlaceHolder:
      "The page is empty. Drag an element from the toolbox or click the button below.",
    toolboxChoiceCategory: "Choice Fields",
    toolboxTextCategory: "Text Input Fields",
    toolboxMatrixCategory: "Matrix Fields",
    resultsTitle: "Field Title",
    resultsName: "Field Name",
    logic: "Logic",
    designer: "Preference Center Designer",
    fpAddQuestion: "Add field...",
    lg: {
      // eslint-disable-next-line
      completedHtmlOnConditionName: 'Set "Complete" page markup',
      question_resetValueName: "Reset field value",
      question_setValueName: "Set field value",
      empty_tab: "Create a rule to customize the flow of the page.",
      trigger_completeName: "Complete page",
      trigger_completeDescription:
        "When the logic expression returns true then the preference gets submitted and an end-user see the 'Thank you page'.",
      trigger_skipDescription:
        "When the logic expression returns true then the page skip to / focus the selected field.",
      trigger_completeText: "Preference gets submitted",
      trigger_skipText: "Skip to the field {0}", // {0} question name
      question_visibilityName: "Show (hide) field",
      question_enableName: "Enable (disable) field",
      question_requireName: "Make field required",
      trigger_setvalueName: "Set field",
      trigger_copyvalueName: "Copy field value",
      trigger_skipName: "Skip to field",
      question_visibilityDescription:
        "Make the field visible when the logic expression returns true. Otherwise keep it invisible.",
      question_enableDescription:
        "Make the field enable when the logic expression returns true. Otherwise keep it disabled.",
      field:
        "Make the field enable when the logic expression returns true. Otherwise keep it disabled.",
      question_requireDescription: "Field becomes required when the logic expression returns true.",
      trigger_setvalueDescription:
        "When field values that are used in the logic expression are changed and the logic expression returns true, then the value is set to the selected field.",
      trigger_copyvalueDescription:
        "When field values that are used in the logic expression are changed and the logic expression returns true, then the value of one selected field is copied to another selected field.",
      trigger_runExpressionDescription:
        "When the logic expression returns true, then the custom expression is performed. You may optionally set this expression result into the selected field",
      question_visibilityText: "Make field {0} visible", // {0} question name
      question_enableText: "Make field {0} enable", // {0} question name
      question_requireText: "Make field {0} required", // {0} question name
      column_visibilityText: "Make column {0} of field {1} visible", // {0} column name, {1} question name
      column_enableText: "Make column {0} of field {1} enable", // {0} column name, {1} question name
      column_requireText: "Make column {0} of field {1} required", // {0} column name, {1} question name
      trigger_setvalueText: "Set into field: {0} value {1}", // {0} question name, {1} setValue
      trigger_copyvalueText: "Copy into field: {0} value from question {1}", // {0} and {1} question names
      trigger_runExpressionText2: " and set its result into field: {0}", // {0} question name
      showAllQuestions: "All Fields",
    },
  },
  pe: {
    requiredErrorText: "Error message for required fields",
    questionsOrder: "Field order",
    logo: "Page Logo",
    mode: "Page display mode",
    defaultValue: "Default Value",
    inputType: "Data type",
    surveyEditorTitle: "Edit settings",
    includeIntoResult: "Include into page results",
    sendResultOnPageNext: "Save partial results in progress",
    surveyTitlePlaceholder: "Title",
    showPreviewBeforeComplete: "Preview preferences before submitting",
    conditionSelectQuestion: "Select field...",
    assistantTitle: "Available fields",
    startWithNewLine: "Display the field on a new line",
    textUpdateMode: "Update text field value",
    hideNumber: "Hide field number",
    emptyLogicPopupMessage: "Select a field to start configuring conditions.",
    completeText: "Complete button text",
    showCompletedPage: "Show the Complete page",
    startSurveyText: "Start button text",
    questionTitleLocation: "Field title location",
    maxTimeToFinish: "Time limit to finish (in seconds)",
    maxTimeToFinishPage: "Maximum time to finish a page",
    hideIfRowsEmpty: "Hide the field if there are no rows",
    questionsOnPageMode: "Page structure",
    choicesFromQuestion: "Copy choices from the following field",
    hideIfChoicesEmpty: "Hide the field if it contains no choices",
    templateTitleLocation: "Field title location",
    showQuestionNumbers: "Show field numbering",
    completedBeforeHtml: "Markup to show if the user already filled out",
    completedHtml: "Complete page markup",
    completedHtmlOnCondition: "Dynamic Complete page markup",
    loadingHtml: "Markup to show while model is loading",
    focusFirstQuestionAutomatic: "Focus first field on a new page",
    questionTitleTemplate: "Field title template, default is: '{no}. {require} {title}'",
    setToName: "Target field",
    fromName: "Field to copy answer from",
    gotoName: "Field to skip to",
    tabs: {
      showOnCompleted: "Complete",
      logo: "Logo",
      question: "Field",
      toolboxMapperCategory: "Mapper",
      toolboxCaptchaCategory: "Captcha",
      questionSettings: "Field Settings",
    },
    noquestions: "There are no fields.",
    triggerCompleteText: "Submit preferences if succeeded.",
    triggerIsVariable: "Do not put the variable into the page result.",
    riggerGotoName: "Go to the field",
    surveyDescriptionPlaceholder: "Enter a description",
    cookieName: "Cookie name (to disable run page two times locally)",
    firstPageIsStarted: "First page is the start page",
    expressionHelp:
      "You can use curly brackets to get access to the field values: {field1} + {field2}, ({price}*{quantity}) * (100 - {discount}). You can use functions as: iif(), today(), age(), min(), max(), count(), avg() and others.",
    goNextPageAutomatic: "On answering all field, go to the next page automatically",
    requiredText: "The field required symbol(s)",
    questionStartIndex: "Field start index (1, 2 or 'A', 'a')",
    questionErrorLocation: "Field error location",
    triggerGotoName: "Go to the field:",
  },
  pv: {
    money: "Money",
    integer: "Integer",
    email: "Email",
    text: "Text",
    url: "Url",
    decimal: "Decimal",
    showAllQuestions: "Show all fields",
    showAnsweredQuestions: "Show answered fields only",
    questions: "Answered fields",
    onSurvey: "Continue throughout",
    onComplete: "When the preference is submitted",
    survey: "Preference Center",
    requiredQuestions: "Answered required fields",
    questionPerPage: "Each field on an individual page",
    singlePage: "All fields on a single page",
    off: "Hide field numbers",
    onHidden: "When the field becomes hidden",
    onHiddenContainer: "When the field or its panel/page becomes hidden",
    correctQuestions: "correct fields",
  },
  theme: {
    questionTitle: "Field box",
    coverInheritWidthFromSurvey: "Same as preference center",
    surveyDescription: "Main description",
    surveyTitle: "Main title",
    themeMode: "Field appearance",
    questionBackgroundTransparency: "Field background opacity",
    names: {
      cd_default: "Default",
      custom: "Custom Theme",
      "custom-dark": "Custom Theme",
    },
  },
  ew: {
    showOnPage: "Show on a page",
    showInWindow: "Show in a window",
    loadFromServer: "Load JSON from server",
  },
  p: {
    widthMode: "Page width mode",
    startSurveyText: "startFormText",
    questionsOrder: "Fields order",
    questionTitleLocation: "Field title location",
    showQuestionNumbers: "Show field numbers",
    focusFirstQuestionAutomatic: "Focus first field automatic",
    questionErrorLocation: "Field error location",
    questionStartIndex: "Field Start Index",
    questionTitleTemplate: "Field title template",
    questionDescriptionLocation: "Field description location",
    questionTitlePattern: "Field title pattern",
    questionsOnPageMode: "Fields on page mode",
    choicesFromQuestion: "Choices from field",
    choicesFromQuestionMode: "Choices from field mode",
    completeText: "completeText",
    completedHtml: "completedHtml",
    showCompletedPage: "showCompletedPage",
  },
  triggers: {
    skiptrigger: "Skip to field",
    completetrigger: "Complete page",
  },
  pehelp: {
    cookieName: "Cookies prevent users from submitting the preference twice.",
  },
};

export default subscriptionsEditorStringsEn;
