export default function OnElementAllowOperations(sender: any, options: any) {
  if (options.element?.crmFieldType === "email") {
    options.allowCopy = false;
  }
  if (options.element?.crmFieldType === "phone") {
    options.allowCopy = false;
  }
  if (options.element?.crmFieldType === "subscriptionlist") {
    options.allowCopy = false;
  }
  if (options.element?.crmFieldType === "global_unsubscribe_email") {
    options.allowCopy = false;
  }
  if (options.element?.crmFieldType === "global_unsubscribe_sms") {
    options.allowCopy = false;
  }
}
